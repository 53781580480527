html,
body {
    overflow-x: hidden;
}

.logo {
    width: 60%;
    max-width: 350px;
}

.hamburger {
    min-width: 50px;
}

.uk-input:not([value=""]):invalid {
    border-color: red;
}

textarea.uk-input {
    min-height: 100px;
}

.uk-button {
    border-radius: 0.25em;
    text-transform: none;
}

.uk-navbar-nav > li > a {
    text-transform: none;
}

.secondary-bg {
    background-color: #f6f6f6;
}

.success-cards > .uk-card > .uk-card-badge {
    font-size: 1.5em;
    width: 2em;
    height: 2em;
}

.price-card {
    border-radius: 1em;
}

.price-button {
    background-color: white;
    border: 1px solid black;
}

.about-card {
    background-color: #ececec;
    border-radius: 1em;
}

.iconBadge {
    width: 75px;
    height: 75px;
    background-color: white;
}

.material-icons {
    color: #333333;
    font-size: 36px !important; /* Preferred icon size */
}

/* Phone or smaller */
@media (max-width: 640px) {
    .edit-calendar {
        left: 100%;
        top: 40%;
    }

    .rocket-launch {
        left: 7%;
        top: 0%;
    }

    .uk-hidden\@xs {
        display: none;
    }

    .lightbulb {
        left: 80%;
        top: 100%;
    }
}

/* Phone landscape and bigger */
@media (min-width: 640px) {
    .edit-calendar {
        left: 100%;
        top: 75%;
    }

    .rocket-launch {
        left: 59%;
        top: 0%;
    }

    .lightbulb {
        left: 30%;
        top: 100%;
    }

    /* Whole */
    .logo\@s {
        width: 60%;
    }

    .uk-show\@s {
        display: initial;
    }
}
